import { Component, HostListener, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-carriers',
  templateUrl: './carriers.component.html',
  styleUrls: ['./carriers.component.scss','./mq-carriers.component.scss']
})
export class CarriersComponent implements OnInit {

  srcWidth : number;
  srcHeight: number;

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private _router : Router
  ) { 
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    // console.log(this.scrHeight, this.scrWidth);
  }

  ngOnInit(): void {
    this.titleService.setTitle(
      "Transy Website Creers Page"
    );
    this.metaService.addTag({
      name: 'description',
      content:
        'Transy is TMS that is quick to implement, scalable, affordable and usefull companies ranging from small sectors to larger sectors. The cloud-based TMS system makes transport management more efficient and easier to control.',
    });
  }

  logisticFlow = [
    {
      img:'./../../../assets/images/companies/create-transport.svg',
      title:'Manage Multiple Organization',
      description:'Transy TMS allows you to manage all your organization bookings in one platforms',
      checkPoints:[
        'Consolidate and assign transportation bookings',
        'Reduce Manual communication '
      ]
    },
    {
      img:'./../../../assets/images/carriers/accept-bookings.svg',
      title:'Accept & Booking transport appointment',
      description:'Transy not only allows you to accept booking, but also allows to reserve time slot and assign driver  ',
      checkPoints:[
        'Accept or reject transport bookings',
        'Reserve time slot and assign driver for transport booking'
      ]
    },
    {
      img:'./../../../assets/images/companies/dashboard-analytics.svg',
      title:'Dashboard Analytics',
      description:'Real time data analysis helps in improving business results and planning in transportations',
      checkPoints:[
        'Real time transportation booking data analytics',
        'Summary of all transportation bookings '
      ]
    }
  ];
  bookingFlow=[
    {
      heading:'Accept Heading',
      desc:'Accept or reject transport booking based on availablity of transport unit'
    },
    {
      heading:'Reserve time slot',
      desc:'Reserve availbal time slot based on dates '
    },
    {
      heading:'Assign transport unit',
      desc:'Assign transport unit and  driver '
    }
  ];

  requestDemo(){
    this._router.navigate(['/contact-us'])
  }

}
