import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-screen',
  templateUrl: './home-screen.component.html',
  styleUrls: ['./home-screen.component.scss','./mq-home-screen.component.scss']
})
export class HomeScreenComponent implements OnInit {

  backgroundColor = '#E5E5E5'

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private _router : Router
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(
      "Transy Website Home Page"
    );
    this.metaService.addTag({
      name: 'description',
      content:
        'Our digital cloud platform helps to increase in process effiency, Improve visibility and minimize costs. Transy trailored to meet all your company needs.',
    });
  }

  managementSolutions = [
    {
      img:'./../../../assets/images/home_screen/control-management.svg',
      heading:'Yard Control Mangement',
      description:'Transt Yard Management Systems  provide increased visibility and better control over the yard operations. Thereby, maximizing the flows of the inbound and outbound goods, reducing costs'
    },
    {
      img:'./../../../assets/images/home_screen/asset-management.svg',
      heading:'Yard Asset Mangement',
      description:'Improved control and management of assets enabled by complete visibility in real time overview of all trailers, containers, reefers, etc.'
    },
    {
      img:'./../../../assets/images/home_screen/shutter-operation.svg',
      heading:'Shunter Operations',
      description:'Automate manual  proccess of assigning task to shunter operator,which can result in increasing yard efficiency and yard jockeys can perform their tasks faster and more effectively.'
    },
    {
      img:'./../../../assets/images/home_screen/self-service-access.svg',
      heading:'Self Service Access',
      description:'With Transy self service access to internal or external staff. Which can get result in cut down of time consuming manual communication, offers additional solutions in accuracy of data, traceability of people on the site.'
    }
  ];

  allFacilities=[
    {
      facility:'inbound/Outbound goods'
    },
    {
      facility:'Visitors to your plant, warehouses and offices'
    },
    {
      facility:'Returnables/empties & waste'
    },
    {
      facility:'Contractors'
    },
    {
      facility:'Container and trailer operations'
    },
    {
      facility:'Quality and security processes'
    }
  ];
  requestDemo(){
    this._router.navigate(['/contact-us'])
  }

}
