import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-carousel',
  templateUrl: './home-carousel.component.html',
  styleUrls: ['./home-carousel.component.scss','./mq-home-carousel.component.scss']
})
export class HomeCarouselComponent implements OnInit {

  srcWidth: number;
  srcHeight : number;

  constructor(
    private _router: Router
  ) { 
    this.getScreenSize();
  }

  ngOnInit(): void {
  }

  slideConfig = {
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "infinite":true,
    "autoplay":true,
    "autoplaySpeed":1500,
    "dots":true,
    "arrows":false,
    "pauseOnHover":false
  };
  
  bigScreenCarousel = [
    {
      bigText1 : 'Plan, Execute & Optimize',
      bigText2:'the shipment of goods',
      smallText:'our cloud based technology allows companies to have real-time visibility on all aspects of the daily yard operations, resulting in increased efficiency and improved on time management for all yard assets.',
      demoBtnBoolean : true
    },
    {
      bigText1:'Plan, Execute & Optimize',
      bigText2:'the shipment of goods of 1',
      smallText:'our cloud based technology allows companies to have real-time visibility on all aspects of the daily yard operations, resulting in increased efficiency and improved on time management for all yard assets.',
      demoBtnBoolean : false
    },
    {
      bigText1:'Plan, Execute & Optimize',
      bigText2:'the shipment of goods of 2',
      smallText:'our cloud based technology allows companies to have real-time visibility on all aspects of the daily yard operations, resulting in increased efficiency and improved on time management for all yard assets.',
      demoBtnBoolean : true
    }
  ];

  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    // console.log(this.scrHeight, this.scrWidth);
  }

  requestDemo(){
    this._router.navigate(['/contact-us'])
  }

}
