<section class="top-navbar">
    <app-top-navbar></app-top-navbar>
</section>

<section>
    <div class="pricing-main-section">
        <div class="small-colored-heading">Pricing</div>
        <div class="light-colored-text">Transy built to increase more efficiency and yard operations, from small yard management to large yard management.</div>
        <div class="billing-cycle-1">Billing Cycle</div>
        <div class="plans-section">
            <div class="plan-tabs">
                <div class="tabs">
                    <div *ngFor="let item of planTabs" [ngClass]="item?.value === 'ANNUAL' ? 'individual-tabs' : ''">
                        <button [ngClass]="item?.status ? 'single-btn-active' : 'single-btn'" (click)="tabClick(item)">{{item?.name}}</button>
                    </div>
                </div>
            </div>
            <div class="billing-section">
                <div class="cards" *ngIf="planValue === 'MONTHLY'">
                    <div *ngFor="let credit of monthlyCredits" [ngClass]="credit?.priceTitle === 'advance' ? 'pro-price-cards' : 'price-cards'">
                        <div class="card-main">
                            <div class="price-btn">
                                <div class="price" [ngClass]="credit?.priceTitle === 'advance' ? 'pro-price' : ''">${{credit?.price}} &nbsp;&nbsp;/month</div>
                                <div class="popular-btn" *ngIf="credit?.priceTitle === 'advance'">
                                    <button>Most Popular</button>
                                </div>
                            </div>
                            <div class="type" [ngClass]="credit?.priceTitle === 'advance' ? 'pro-type' : ''">{{credit?.priceTitle | titlecase}}</div>
                            <div class="plan-description" [ngClass]="credit?.priceTitle === 'advance' ? 'pro-plan-description' : ''">{{credit?.packagedesc}}</div>
                            <div class="all-pros">
                                <div class="single-pro" *ngFor="let item of credit?.pros">
                                    <img src="./../../../assets/images/pricing/yes.svg" alt="right-mark" *ngIf="item?.check">
                                    <img src="./../../../assets/images/pricing/no.svg" alt="wrong-mark" *ngIf="!item?.check">
                                    <div class="pros-text" [ngClass]="credit?.priceTitle === 'advance' ? 'pro-pros-text' : ''">{{item?.text}}</div>
                                </div>
                            </div>
                            <div class="choose-plan" [ngClass]="credit?.priceTitle === 'advance' ? 'pro-choose-plan' : ''">
                                <button>Choose Plan</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cards" *ngIf="planValue === 'ANNUAL'">
                    <div *ngFor="let credit of annualCredits" [ngClass]="credit?.priceTitle === 'advance' ? 'pro-price-cards' : 'price-cards'">
                        <div class="card-main">
                            <div class="price-btn">
                                <div class="price" [ngClass]="credit?.priceTitle === 'advance' ? 'pro-price' : ''">${{credit?.price}} &nbsp;&nbsp;/month</div>
                                <div class="popular-btn" *ngIf="credit?.priceTitle === 'advance'">
                                    <button>Most Popular</button>
                                </div>
                            </div>
                            <div class="type" [ngClass]="credit?.priceTitle === 'advance' ? 'pro-type' : ''">{{credit?.priceTitle | titlecase}}</div>
                            <div class="plan-description" [ngClass]="credit?.priceTitle === 'advance' ? 'pro-plan-description' : ''">{{credit?.packagedesc}}</div>
                            <div class="all-pros">
                                <div class="single-pro" *ngFor="let item of credit?.pros">
                                    <img src="./../../../assets/images/pricing/yes.svg" alt="right-mark" *ngIf="item?.check">
                                    <img src="./../../../assets/images/pricing/no.svg" alt="wrong-mark" *ngIf="!item?.check">
                                    <div class="pros-text" [ngClass]="credit?.priceTitle === 'advance' ? 'pro-pros-text' : ''">{{item?.text}}</div>
                                </div>
                            </div>
                            <div class="choose-plan" [ngClass]="credit?.priceTitle === 'advance' ? 'pro-choose-plan' : ''">
                                <button>Choose Plan</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="faq-section">
            <div class="medium-black-heading">Frequently Asked Question</div>
            <div class="question-answers">
                <mat-accordion>
                    <!-- #docregion basic-panel -->
                    <!-- #enddocregion basic-panel -->
                    <div *ngFor="let item of faq; let i =  index">
                        <mat-expansion-panel (opened)="open(item,i)"
                        (closed)="close(item,i)" class="expansion-panel">
                            <mat-expansion-panel-header class="faq-header">
                                <!-- <mat-icon *ngIf="!panelOpenState">add</mat-icon>
                                <mat-icon *ngIf="panelOpenState">remove</mat-icon> -->
                                <mat-panel-title>
                                    {{item?.question}}
                                </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="showing-answer">
                                    {{item?.answer1}}
                                </div>
                                <!-- <div class="showing-answer">
                                    {{item?.answer2}}
                                </div> -->
                        </mat-expansion-panel>
                    </div>
                </mat-accordion>
                <div class="contact-us">Have more questions? <span style="color:#FB8500; font-weight: 700;">ContactUs</span></div>
            </div>
        </div>
        <div class="above-footer">
            <!-- <div class="above-footer-heading">Start Generating accurate leads with our AI enabled platform</div> -->
            <div class="love">Subscribe And Start Managing Now</div>
            <div class="secret">Increase efficiency and improved on yard management operations</div>
            <div class="double-dhamaka">
                <button class="subscribe">Subscribe Now</button>
                <button class="request-demo">Request Demo</button>
            </div>
        </div>
    </div>
</section>

<section class="footer">
    <app-footer></app-footer>
</section>
