<section class="top-navbar">
    <app-top-navbar></app-top-navbar>
</section>
<section class="main-section">
    <div class="title-section">
        <div class="left-title-content">
            <div class="title-heading">
               <span style="color:#FB8500; font-weight: 700;">Uplift and Streamline</span> Your <br> Logistics With Transport <br> Management
            </div>
            <div class="description-part">
                The most flexible and easy-to-use cloud-based logistic transport management solution for enabling efficiency.
            </div>
            <div class="request-demo-btn">
                <button (click)="requestDemo()">Request Demo</button>
            </div>
        </div>
        <div class="right-image-section">
            <img src="./../../../assets/images/companies/main-image.svg" alt="title-image">
        </div>
    </div>

    <div class="describe-logistics-section">
        <div class="solution-big-text">A better way to manage your logistics</div>
        <div class="solution-small-text">Transy is TMS that is quick to implement, scalable, affordable and usefull companies ranging from small sectors to larger sectors. The cloud-based TMS system makes transport management more efficient and easier to control.</div>
        <div class="ratio-part">
            <div class="single-ratio">
                <div class="percentage">80%</div>
                <div class="percent-text">Less Calls</div>
            </div>
            <div class="single-ratio">
                <div class="percentage">100%</div>
                <div class="percent-text">Efficiency</div>
            </div>
            <div class="single-ratio">
                <div class="percentage">20%</div>
                <div class="percent-text">Time Saving</div>
            </div>
        </div>
        <div class="background-img">
            <div class="background-left">
                <div class="background-big-text">Put an end to Manual Paper Work</div>
                <div class="background-small-text">Start using transy today strop manual paper work</div>
            </div>
            <div class="background-right">
                <div class="demo-btn" (click)="requestDemo()">
                    <!-- <button>Request Demo</button> -->
                    <span>Request Demo</span>
                </div>
            </div>
        </div>
        <div class="logistic-flow">
            <div class="single-logistic" *ngFor="let item of logisticFlow;let i = index; let even=even; let odd = odd">
                <div class="check-values" [ngClass]="even ? '':'reverse-flex-direction'">
                    <div class="logistic-left-part" >
                        <img [src]="item?.img" [alt]="item.title">
                    </div>
                    <div class="logistic-right-part">
                        <div class="flow-heading">{{item?.title}}</div>
                        <div class="flow-description">{{item?.description}}</div>
                        <div class="all-steps">
                            <div class="single-step" *ngFor="let point of item.checkPoints">
                                <img src="./../../../assets/images/companies/step-point-check.svg" alt="check">
                                <div class="step-point">{{point}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="demo-request">
        <!-- <mat-form-field class="sfull-width" appearance="outline">
            <input matInput />
        </mat-form-field> -->
        <div class="request-title">Get live Demo request now</div>
        <div class="buttonIn">
            <input type="text" id="enter" placeholder="Enter mail id">
            <button id="clear">Get Demo</button>
        </div>
    </div>
</section>
<section class="footer">
    <app-footer></app-footer>
</section>