<section [style.backgroundColor]="background">
    <div class="centered-white-box">
        <div class="contact-form" id="contactForm">
            <div class="form-left-section">
                <div class="big-text">
                    Let's Get In Touch
                </div>
            </div>
            <div class="form-right-section">
                <div class="form">
                    <div class="label-heading">Name<sup>*</sup></div>
                    <mat-form-field class="sfull-width" appearance="outline">
                        <input matInput placeholder="Enter Name" name="name" [(ngModel)]="name" [autocomplete]="false"/>
                    </mat-form-field>
                </div>
                <div class="form">
                    <div class="label-heading">E-mail<sup>*</sup></div>
                    <mat-form-field class="sfull-width" appearance="outline">
                        <input matInput placeholder="Enter E-mail" name="email" [(ngModel)]="email"/>
                    </mat-form-field>
                </div>
                <div class="form">
                    <div class="label-heading">Message</div>
                    <div class="text-area-sfull-width">
                        <textarea rows="4"  [cols]="cols" placeholder="Message Here" name="message" [(ngModel)]="message"></textarea>
                    </div>
                </div>
                <div class="contact-btn">
                    <button class="cursor-pointer" (click)="contactFormSubmission()">Contact Us</button>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom-box" [style.marginTop.px]="contactFormHeight" >
        <div class="inside-footer-main" [style.marginTop.px]="contactFormHeight+30">
            <div class="branding-logo">
                <img src="./../../../../assets/images/footer/footer_branding_logo.svg" alt="branding-logo">
                <span id="brandingLogo"></span>
            </div>
            <div class="footer-items">
                <div class="items" *ngFor="let item of footerItemArray">
                    {{item.name | titlecase}}
                </div>
            </div>
            <div class="rights-media">
                <div class="social-media-icons" [style.width.%]="setSocialMedia">
                    <i class="fa fa-facebook-square cursor-pointer" aria-hidden="true" (click)="socialMediaClick('facebook')"></i>
                    <i class="fa fa-twitter-square cursor-pointer" aria-hidden="true" (click)="socialMediaClick('twitter')"></i>
                    <i class="fa fa-linkedin-square cursor-pointer" aria-hidden="true" (click)="socialMediaClick('linkedIn')"></i>
                </div>
                <div class="copy-rights">
                    <mat-icon>copyright</mat-icon> &nbsp;2022, All rights reserved
                </div>
            </div>
        </div>
    </div>
</section>
