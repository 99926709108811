<section class="top-navbar">
    <app-top-navbar></app-top-navbar>
</section>

<section>
    <!-- <div class="main-screen">
        <div class="colored-heading-text">Plan, Execute & Optimize</div>
        <div class="white-heading-text">the shipment of goods</div>
        <div class="small-text">
            our cloud based technology allows companies to have real-time visibility on all aspects of the daily yard operations, resulting in increased efficiency and improved on time management for all yard assets.
        </div>
        <div class="demo-btn">
            <button class="cursor-pointer">Request Demo</button>
        </div>
    </div> -->
    <div class="top-section">
        <app-home-carousel></app-home-carousel>
    </div>


    <div class="about-section">
        <div class="about-img-section">
            <img src="./../../../assets/images/home_screen/about-us.svg" alt="about-us-community">
        </div>
        <div class="about-content-section">
            <div class="about-small-colored-text">About Transy</div>
            <div class="about-colored-text">Technology partner who made Yard management more managable</div>
            <div class="about-small-text">Transy Yard Management will help you efficiently manage and optimally direct trailers in the yard as an extension of your warehouse to ensure consistent and strong performance.</div>
            <div class="contact-btn">
                <button class="cursor-pointer">Contact Us</button>
            </div>
        </div>
    </div>

    <div class="one-stop-solution">
        <div class="solution-big-text">One stop solution for all your yard management</div>
        <div class="solution-small-text">Our digital cloud platform helps to increase in process effiency, Improve visibility and minimize costs. Transy trailored to meet all your company needs.</div>
        <div class="all-managment-solutions">
            <div class="single-manangement-solution" *ngFor="let solution of managementSolutions">
                <img [src]="solution.img" alt="control-management" loading="lazy">
                <div class="management-heading">{{solution?.heading}}</div>
                <div class="management-description">{{solution?.description}}</div>
            </div>
        </div>
    </div>

    <div class="manual-paperwork-section">
        <div class="background-img">
            <div class="background-left">
                <div class="background-big-text">Put an end to Manual Paper Work</div>
                <div class="background-small-text">Start using transy today strop manual paper work</div>
            </div>
            <div class="background-right">
                <div class="demo-btn">
                    <button class="cursor-pointer" (click)="requestDemo()">Request Demo</button>
                </div>
            </div>
        </div>
        <div class="paperwork-details">
            <div class="details-big-text">
                Reshape your yard management with our <span style="color: #FB8500;">hassle-free</span> solutions
            </div>
            <div class="details-small-text">
                Transt Yard Management Systems  provide increased visibility and better control over the yard operations. Thereby, maximizing the flows of the inbound and outbound goods, reducing costs
            </div>
            <div class="all-fecilities">
                <div class="single-facility" *ngFor="let item of allFacilities">
                    <img src="./../../../assets/images/home_screen/success.svg" alt="success-mark" width="22px" loading="lazy">
                    <div class="facility">{{item.facility | titlecase}}</div>
                </div>
            </div>
            <div class="subscribe-option">
                <div class="subscribe-left">
                    <div class="subscribe-big-text">Subscribe and Start Managing Your Yard</div>
                    <div class="subscribe-small-text">An afforable pricing plan that best fits your needs.</div>
                    <div class="subscribe-btn">
                        <button class="cursor-pointer">Subscribe Now</button>
                    </div>
                </div>
                <div class="subscribe-right">
                    <div class="starts-text">Starts from</div>
                    <div class="offering-price">&#36;20 / Month</div>
                </div>
            </div>
        </div>
    </div>

    <div class="business-impact">
        <div class="business-impact-title">Business Impact</div>
        <div class="buisiness-impact-sub-text">With our research team we found that transy can impact your business values</div>
        <div class="all-status">
            <div class="single-status">
                <div class="first-circle">
                    95%
                </div>
                <div class="first-text">Increase in On-Time Delivery</div>
            </div>
            <div class="single-status">
                <div class="second-circle">
                    80%
                </div>
                <div class="first-text">Operational Savings</div>
            </div>
            <div class="single-status">
                <div class="third-circle">
                    60%
                </div>
                <div class="first-text">Management Analysis Saving</div>
            </div>
            <div class="single-status">
                <div class="fourth-circle">
                    50%
                </div>
                <div class="first-text">Management Analysis Saving</div>
            </div>
        </div>
    </div>

    <div class="management-section">
        <div class="anywhere-left">
            <div class="anywhere-big-text">Manage from anywhere</div>
            <div class="anywhere-small-text">Our AI platform is a responsive web-app whether you’re on move or anywhere. Our platform is available across all smart devices which allows to manage anywhere you like.</div>
            <div class="demo-btn">
                <button>Request For Demo</button>
            </div>
        </div>
        <div class="anywhere-right">
            <img src="./../../../assets/images/home_screen/mange-from-anywhere.svg" alt="manage-from-anywhere">
        </div>
    </div>
</section>

<section class="footer">
    <app-footer [background]="backgroundColor"></app-footer>
</section>