import { AfterViewInit, Component, HostListener, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss','./mq-footer.component.scss']
})
export class FooterComponent implements OnInit{

  @Input() background;

  srcHeight ;
  srcWidth ;
  cols;
  contactFormHeight : number;
  footerFomHeight : number;
  name = '';
  email = '';
  message = '';
  
  constructor(
    private _snackbar : MatSnackBar,
    private commonService:CommonService
  ) { 
    this.getScreenSize();
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    // console.log(this.scrHeight, this.scrWidth);
  }

  ngOnInit(): void {
    this.getContactFormBoundingValues();
    if(this.srcWidth > 475){
      this.cols = 72;
    }
    else{
      this.cols = 35;
    }
  }

  setSocialMedia : Number;
  getContactFormBoundingValues(){
    const getId = document.getElementById('contactForm');
    const values =getId.getBoundingClientRect()
    this.contactFormHeight = Math.ceil(values.height/2);
    this.footerFomHeight = (Math.ceil(values.height/2) + 100);

    //logo position
    const logoId = document.getElementById('brandingLogo');
    const getPosition = logoId.getBoundingClientRect();
    console.log(getPosition);
    this.setSocialMedia = Math.ceil((getPosition.left/1024)*100)-24
    
  }

  footerItemArray = [
    {
      name:'For Companies',
      redirectingUrl : 'companies',
      status:false
    },
    {
      name:'For Carriers',
      redirectingUrl:'career',
      status:false
    },
    {
      name:'Product',
      redirectingUrl:'product',
      status:false
    },
    {
      name:'Pricing',
      redirectingUrl:'pricing',
      status:false
    },
    {
      name:'Contact Us',
      redirectingUrl:'contact-us',
      status:false
    }
  ];

  contactFormSubmission(){
    if(this.name === '' && this.email === ''){
      this._snackbar.open(
        `Please Fill Mandatory Data`,
        'Close',
        {
          duration:1500
        }
      )
    }
    else{
      let details={
        email: this.email,
        message: this.message,
        name:this.name
      }
     this.commonService.addContactUsDetails(details).subscribe(
      {
        next:(res:any)=>{
        this.email="";
        this.name="";
        this.message="";
          this._snackbar.open(
            "Sent successfully",'Close',
            {
              duration:500
            }
          )
        },
        error:(err)=>{
          this._snackbar.open(
            "Something went wrong please try again",'Close',
            {
              duration:500
            }
          )
        }
      }
    
     )
    }
  }

  socialMediaClick(item){
    if(item === 'facebook'){
      window.open('https://www.facebook.com','_blank')
      // window.location.href = 'https://www.facebook.com'
    }
    else if(item === 'twitter'){
      window.open('https://www.twitter.com','_blank')
      // window.location.href = 'https://www.twitter.com'
    }
    else{
      window.open('https://www.linkedin.com','_blank')
      // window.location.href = 'https://www.linkedin.com'
    }
  }

}
