import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss','./mq-companies.component.scss']
})
export class CompaniesComponent implements OnInit {

  contactFormBoolean : boolean = false;
  backgroundColor = '#f0f0f0'

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private _router : Router
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(
      "Transy Website Companies Page"
    );
    this.metaService.addTag({
      name: 'description',
      content:
        'The most flexible and easy-to-use cloud-based logistic transport management solution for enabling efficiency.',
    });
  }

  logisticFlow = [
    {
      img:'./../../../assets/images/companies/create-transport.svg',
      title:'Create Multiple Transporter',
      description:'Transy TMS allows to create multiple transporter to assign order. So adding preferred transporter for transport booking can be easy to accept or decline transport request',
      checkPoints:[
        'Directly assigning booking to transporter',
        'TMS for transporter to manage bookings',
        'Booking summary of transporter'
      ]
    },
    {
      img:'./../../../assets/images/companies/control-site.svg',
      title:'Gain control with Sites',
      description:'Plan and execute your shipment Transportation and have visibility over sites  transports by creating multiple sites',
      checkPoints:[
        'Order Management by site level',
        'Multiple Site creation and  access site related  order information',
        'Can give access to site level to managers, Instead of showing all  '
      ]
    },
    {
      img:'./../../../assets/images/companies/time-slot-config.svg',
      title:'Time Slot Configuration',
      description:'With Transy TMS one can prepare proper planning for handling Inbound and Outbound loading time. Optimize internal process and cut down waiting time',
      checkPoints:[
        'Multi Site level time slot configuration',
        'Time Length duration for loading or unloading',
        'Centralized information of time slot summary'
      ]
    },
    {
      img:'./../../../assets/images/companies/transport-order.svg',
      title:'Transport Order Booking ',
      description:'Transy TMS allows to create and manage transport bookings. Assign transport bookings directly to transporter and reduce manual coordination effort',
      checkPoints:[
        'Creating & assigning booking to transporter with one-click',
        'Defining load capacity or commodity ',
        'Setting up Source and destination location '
      ]
    },
    {
      img:'./../../../assets/images/companies/dashboard-analytics.svg',
      title:'Dashboard Analytics',
      description:'Real time data analysis helps in improving business results and planning in transport booking',
      checkPoints:[
        'Real time transport booking data',
        'Time slot summary of Inbound and Outbound load'
      ]
    }
  ]
  requestDemo(){
    this._router.navigate(['/contact-us'])
  }

}
