import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss','./mq-top-navbar.component.scss']
})
export class TopNavbarComponent implements OnInit {
  srcHeight : Number;
  srcWidth : Number;

  constructor(
    private _router: Router
  ) { 
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    // 
  }

  ngOnInit(): void {
  }

  navItems1 : any[] = [
    {
      name:'For Companies',
      redirectingUrl : 'companies',
      status:false
    },
    {
      name:'For Carriers',
      redirectingUrl:'career',
      status:false
    }
  ]
  navItems2 : any[] = [
    {
      name:'Pricing',
      redirectingUrl:'pricing',
      status:false
    },
    {
      name:'Contact Us',
      redirectingUrl:'contact-us',
      status:false
    }
  ]

  dropDownProviding : any [] = [
    {
      name:'Product Transport Planning & Execution',
      redirectingUrl:'transport-planing&excution',
      status:false
    },
    {
      name:'Real Time Yard Management',
      redirectingUrl:'yard-management',
      status:false
    }
  ];

  navItemRedirect1(item){
    this.navItems1.forEach(element => {
      if(element.name === item.name){
        element.status = true
        this._router.navigate([`/${element.redirectingUrl}`]);
        window.scroll({
          top:0,
          left:0,
          behavior :'smooth'
        });
      }
      else{
        element.status = false;
      }
    });
  }

  navItemRedirect2(item){
    this.navItems2.forEach(element => {
      if(element.name === item.name){
        element.status = true
        this._router.navigate([`/${element.redirectingUrl}`]);
        window.scroll({
          top:0,
          left:0,
          behavior :'smooth'
        });
      }
      else{
        element.status = false;
      }
    });
  }

  redirectUrl(item,i){
    this.dropDownProviding.forEach((element,index) => {
      if(i === index){
        this._router.navigate([`${element.redirectingUrl}`]);
        window.scroll({
          top:0,
          left:0,
          behavior :'smooth'
        });
      }
    });
  }

  requestDemo(){
    this._router.navigate(['/contact-us'])    
  }

  redirectToAnotherUrl(){
    window.location.href = environment.signinRedirection
  }

}
