import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-yard-management',
  templateUrl: './yard-management.component.html',
  styleUrls: ['./yard-management.component.scss','mq-yard-management.component.scss']
})
export class YardManagementComponent implements OnInit {

  contactFormBoolean : boolean = false;
  backgroundColor = '#E5E5E5'

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private _router : Router
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(
      "Transy Website Yard-Management Page"
    );
    this.metaService.addTag({
      name: 'description',
      content:
        'Transy, is a software solution that automates processes of manually planning and tracking your daily yard operations',
    });
  }

  logisticFlow = [
    {
      img:'./../../../assets/images/yard_management/control-management.svg',
      title:'Yard Control Mangement',
      description:'Transy software provide real-time visibility to asset locations and the operational status. YMS give real-time information on the trailer locations in the yard and allows employees to move trailers across staging and docks in an efficient manner.Transy TMS allows to create multiple transporter to assign order. So adding preferred transporter for transport booking can be easy to accept or decline transport request',
      checkPoints:[
        'Tracking of carrier commitments',
        'Reduce idle dock labor',
        'Reduce detention & demurrage expenses'
      ]
    },
    {
      img:'./../../../assets/images/yard_management/asset-management.svg',
      title:'Yard Asset Mangement',
      description:'Detect inefficiencies and optimize loading and unloading times with Improved control and management of assets, enabled by complete visibility in real time overview of all trailers, containers, reefers, etc. Often it is possible to provide flawless operations',
      checkPoints:[
        'No more incorrect pick-ups',
        'Reduce idle rental',
        'Visibility of yard operations & assets'
      ]
    },
    {
      img:'./../../../assets/images/yard_management/shunter-option.svg',
      title:'Shunter Operations',
      description:'Automate manual  proccess of assigning task to shunter operator,which can result in increasing yard efficiency and yard jockeys can perform their tasks faster and more effectively.',
      checkPoints:[
        'Improve operator velocity & volume',
        'Radio communication becomes unnecessary',
        'High-quality, structured feedback'
      ]
    },
    {
      img:'./../../../assets/images/yard_management/self-service-access.svg',
      title:'Self Service Access',
      description:'With Transy self service access to internal or external staff. Which can get result in cut down of time consuming manual communication, offers additional solutions in accuracy of data, traceability of people on the site.',
      checkPoints:[
        'No more incorrect pick-ups',
        'Reduce idle rental',
        'Visibility of yard operations & assets'
      ]
    }
  ];

  requestDemo(){
    this._router.navigate(['/contact-us'])
  }
}
