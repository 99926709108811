<section class="top-navbar">
    <app-top-navbar></app-top-navbar>
</section>

<section>
    <div class="main-section">
        <div class="centered-white-box">
            <div class="contact-form" id="contactForm">
                <div class="form-left-section">
                    <div class="big-text">
                        Make your move, and we'll be in touch.
                    </div>
                </div>
                <div class="form-right-section">
                    <div class="form">
                        <div class="label-heading">Name<sup>*</sup></div>
                        <mat-form-field class="sfull-width" appearance="outline">
                            <input matInput placeholder="Enter Name" name="name" [(ngModel)]="name" [autocomplete]="false"/>
                        </mat-form-field>
                    </div>
                    <div class="form">
                        <div class="label-heading">Phone No.<sup>*</sup></div>
                        <mat-form-field class="sfull-width" appearance="outline">
                            <input matInput placeholder="Enter Phone Number" name="name" [(ngModel)]="phoneNumber" [autocomplete]="false"/>
                        </mat-form-field>
                    </div>
                    <div class="form">
                        <div class="label-heading">E-mail<sup>*</sup></div>
                        <mat-form-field class="sfull-width" appearance="outline">
                            <input matInput placeholder="Enter E-mail" name="email" [(ngModel)]="email"/>
                        </mat-form-field>
                    </div>
                    <div class="form">
                        <div class="label-heading">Message</div>
                        <div class="text-area-sfull-width">
                            <textarea rows="4"  [cols]="cols" placeholder="Message Here" name="message" [(ngModel)]="message"></textarea>
                        </div>
                    </div>
                    <div class="contact-btn">
                        <button class="cursor-pointer" (click)="contactFormSubmission()">Contact Us</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="footer">
    <app-without-form-footer></app-without-form-footer>
</section>
