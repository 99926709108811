<ngx-slick-carousel class="carousel" 
#slickModal="slick-carousel" 
[config]="slideConfig" >
<div ngxSlickItem *ngFor="let slide of bigScreenCarousel" class="slide">
    <div class="story" [ngClass]="srcWidth > 475 ? '':'short-screen'">
        <div class="colored-heading-text">{{slide?.bigText1}}</div>
        <div class="white-heading-text">{{slide?.bigText2}}</div>
        <div class="small-text">
            {{slide?.smallText}}
        </div>
        <div class="demo-btn" *ngIf="slide?.demoBtnBoolean">
            <button class="cursor-pointer" (click)="requestDemo()">Request Demo</button>
        </div>
    </div>
</div>
</ngx-slick-carousel>