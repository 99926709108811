import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModuleModule } from './material-module/material-module.module';
import { HomeScreenComponent } from './all-components/home-screen/home-screen.component';
import { TopNavbarComponent } from './all-components/shared-components/top-navbar/top-navbar.component';
import { FooterComponent } from './all-components/shared-components/footer/footer.component';
import { HomeCarouselComponent } from './all-components/shared-components/home-carousel/home-carousel.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CompaniesComponent } from './all-components/companies/companies.component';
import { CarriersComponent } from './all-components/carriers/carriers.component';
import { ProductTransportComponent } from './all-components/product-transport/product-transport.component';
import { YardManagementComponent } from './all-components/yard-management/yard-management.component';
import { PricingComponent } from './all-components/pricing/pricing.component';
import { WithoutFormFooterComponent } from './all-components/shared-components/without-form-footer/without-form-footer.component';
import { ContactUsComponent } from './all-components/contact-us/contact-us.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeScreenComponent,
    TopNavbarComponent,
    FooterComponent,
    HomeCarouselComponent,
    CompaniesComponent,
    CarriersComponent,
    ProductTransportComponent,
    YardManagementComponent,
    PricingComponent,
    WithoutFormFooterComponent,
    ContactUsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    MaterialModuleModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SlickCarouselModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
