import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    public dialog: MatDialog,
    private snackbar: MatSnackBar,
    private http: HttpClient,
  ) { }

  openPopup(componentName:any, height:any, width:any, dataModel:any) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true
    });
  }

  addContactUsDetails(data:any){
    return this.http.post(
      environment.baseUrl+`/contactUs/v1/create`,data
    )
  }
}
