<section class="top-navbar">
    <app-top-navbar></app-top-navbar>
</section>

<section class="main-section">
    <div class="title-section">
        <div class="left-title-content">
            <div class="title-heading">
               <!-- <span style="color:#FB8500; font-weight: 700;">Planning and managing</span> <br> transportation booking made <br> more manageable -->
               Improve Transport <br> Management More <span style="color:#FB8500; font-weight: 700;">Effectively</span>
            </div>
            <div class="description-part">
                Being able to plan effectively for receipt of in-bound and out-bound shipments, keeping customers up-to-date and automating numerous time-intensive processes including carrier selection, route planning, documentation and more
            </div>
            <div class="request-demo-btn">
                <button (click)="requestDemo()">Request Demo</button>
            </div>
        </div>
        <div class="right-image-section">
            <img src="./../../../assets/images/transport_planinig/main-image.svg" alt="title-image">
        </div>
    </div>

    <div class="describe-logistics-section">
        <div class="solution-big-text">Cloud based Platform to Optimize transport Executions</div>
        <div class="solution-small-text">Increase efficiency of transport executions and cut down waiting time of transports</div>
        <!-- <div class="ratio-part">
            <div class="single-ratio">
                <div class="percentage">80%</div>
                <div class="percent-text">Less Calls</div>
            </div>
            <div class="single-ratio">
                <div class="percentage">100%</div>
                <div class="percent-text">Efficiency</div>
            </div>
            <div class="single-ratio">
                <div class="percentage">20%</div>
                <div class="percent-text">Time Saving</div>
            </div>
        </div> -->
        <div class="logistic-flow">
            <div class="single-logistic" *ngFor="let item of logisticFlow;let i = index; let even=even; let odd = odd">
                <div class="check-values" [ngClass]="even ? '':'reverse-flex-direction'">
                    <div class="logistic-left-part" >
                        <img [src]="item?.img" [alt]="item.title">
                    </div>
                    <div class="logistic-right-part">
                        <div class="flow-heading">{{item?.title | titlecase}}</div>
                        <div class="flow-description">{{item?.description1}}</div>
                        <div class="flow-description">{{item?.description2}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="background-img">
            <div class="background-left">
                <div class="background-big-text">Put an end to Manual Paper Work</div>
                <div class="background-small-text">Start using transy today strop manual paper work</div>
            </div>
            <div class="background-right">
                <div class="demo-btn" (click)="requestDemo()">
                    <!-- <button>Request Demo</button> -->
                    <span>Request Demo</span>
                </div>
            </div>
        </div>
        <div class="transy-benefits">
            <div class="benefits-heading">Transy Benefits</div>
            <div class="benefits-sub-text">We’re partners in your growth journey, and trusted by
                 leading organisations across the globe for our unparalleled expertise and business value proposition</div>
            <div class="all-benefits">
                <div class="single-benefit" *ngFor="let item of benefits">
                    <img [src]="item?.imgUrl" [alt]="item?.alt"/>
                    <div class="benefit-description">
                        {{item?.text | titlecase}}
                    </div>
                </div>
            </div>
        </div>
        <div class="business-impact">
            <div class="all-status">
                <div class="single-status">
                    <div class="first-circle">
                        95%
                    </div>
                    <div class="first-text">Increase in On-Time Delivery</div>
                </div>
                <div class="single-status">
                    <div class="second-circle">
                        80%
                    </div>
                    <div class="first-text">Reduction in Waiting time</div>
                </div>
                <div class="single-status-text">
                    <div class="business-impact-title">Business Impact</div>
                    <div class="buisiness-impact-sub-text">With our research team we found that transy can impact your business values</div>
                </div>
            </div>
        </div>
    
    </div>

    <div class="working-process">
        <div class="process-heading">How it works</div>
        <div class="process-description">
            We made transport booking process simple for everyone from accepting transport booking to assign driver
        </div>
        <div class="processs-flow" *ngIf="srcWidth > 475">
            <div class="single-process" *ngFor="let item of bookingFlow; last as last">
                <div class="flow">
                    <img [src]="item?.imgUrl" [alt]="item?.alt">
                    <div class="flow-heading1">{{item?.heading}}</div>
                    <div class="flow-desc">
                        {{item?.desc}}
                    </div>
                </div>
                <img src="./../../../assets/images/carriers/left-arrow.svg" alt="" *ngIf="!last" class="arrow">
            </div>
        </div>
        <div class="processs-flow" *ngIf="srcWidth < 475">
            <div class="single-process" *ngFor="let item of bookingFlow; last as last">
                <div class="flow">
                    <img [src]="item?.imgUrl" [alt]="item?.alt">
                    <div class="flow-heading">{{item?.heading}}</div>
                    <div class="flow-desc">
                        {{item?.desc}}
                    </div>
                </div>
                <img src="./../../../assets/images/carriers/down-arrow.svg" alt="" *ngIf="!last" class="arrow">
            </div>
        </div>
    </div>
</section>
<section class="footer">
    <app-footer></app-footer>
</section>