import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CarriersComponent } from './all-components/carriers/carriers.component';
import { CompaniesComponent } from './all-components/companies/companies.component';
import { ContactUsComponent } from './all-components/contact-us/contact-us.component';
import { HomeScreenComponent } from './all-components/home-screen/home-screen.component';
import { PricingComponent } from './all-components/pricing/pricing.component';
import { ProductTransportComponent } from './all-components/product-transport/product-transport.component';
import { YardManagementComponent } from './all-components/yard-management/yard-management.component';

const routes: Routes = [
  {
    path:'',
    component:HomeScreenComponent
  },
  {
    path:'companies',
    component:CompaniesComponent
  },
  {
    path:'career',
    component:CarriersComponent
  },
  {
    path:'transport-planing&excution',
    component:ProductTransportComponent
  },
  {
    path:'yard-management',
    component:YardManagementComponent
  },
  {
    path:'pricing',
    component:PricingComponent
  },
  {
    path:'contact-us',
    component:ContactUsComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
