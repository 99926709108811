import { Component, HostListener, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-transport',
  templateUrl: './product-transport.component.html',
  styleUrls: ['./product-transport.component.scss','./ma-product-tranport.component.scss']
})
export class ProductTransportComponent implements OnInit {

  srcWidth : number;
  srcHeight: number;

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private _router : Router
  ) { 
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    // console.log(this.scrHeight, this.scrWidth);
  }

  ngOnInit(): void {
    this.titleService.setTitle(
      "Transy Website Product-Transport Page"
    );
    this.metaService.addTag({
      name: 'description',
      content:
        'Being able to plan effectively for receipt of in-bound and out-bound shipments, keeping customers up-to-date and automating numerous',
    });
  }

  logisticFlow = [
    {
      img:'./../../../assets/images/transport_planinig/transport-booking.svg',
      title:'hassle free Transport booking',
      description1:'Our digital cloud platform helps to Streamline transport booking and increase in process effiency.',
      description2:'Create and assign transport booking to transporter with one click and significantly reduce manual communication by 60%'
    },
    {
      img:'./../../../assets/images/transport_planinig/wait-time.svg',
      title:'Cut down wating time with slot manager',
      description1:'Improve your logistic transportation planning of Inbound and outbound with more predictable loading and unloading schedules',
      description2:'Reduce more waiting of truck, save money on overtime process and increase handling capacity'
    }
  ];
  bookingFlow=[
    {
      imgUrl:'./../../../assets/images/transport_planinig/slot-manager.svg',
      heading:'Define Slot Manager',
      desc:'Define all time slots for Inbound and Outbound transports.',
      alt:'Slot Manager'
    },
    {
      imgUrl:'./../../../assets/images/transport_planinig/order-booking.svg',
      heading:'Creating Order Booking',
      desc:'Create transport booking  and add Source and destination place',
      alt:'Order Booking'
    },
    {
      imgUrl:'./../../../assets/images/transport_planinig/assign-transporter.svg',
      heading:'Assign transporter',
      desc:'Assign preferred transporter directly with one click',
      alt:'Assign Transporter'
    },
    {
      imgUrl:'./../../../assets/images/transport_planinig/choose-time-slot.svg',
      heading:'Choose Time slot',
      desc:'Choose prefered time slot and delivery on time',
      alt:'Time Slot'
    }
  ];

  benefits=[
    {
      imgUrl:'./../../../assets/images/transport_planinig/time-saving.svg',
      text:'Time savings—fewer manual steps result in fewer delays and faster delivery times',
      alt:'Time Saving'
    },
    {
      imgUrl:'./../../../assets/images/transport_planinig/gusiness-growth.svg',
      text:'New business insights as better reporting leads to faster action and process improvement',
      alt:'Buisiness improvement'
    },
    {
      imgUrl:'./../../../assets/images/transport_planinig/transport.svg',
      text:'Easy to manage Inbound and Outbound vehicle with propert slot defining ',
      alt:'Easy To Manage'
    }
  ];

  requestDemo(){
    this._router.navigate(['contact-us']);
  }

}
