<section class="top-navbar">
    <app-top-navbar></app-top-navbar>
</section>

<section class="main-section">
    <div class="title-section">
        <div class="left-title-content">
            <div class="title-heading">
               <span style="color:#FB8500; font-weight: 700;">Planning and managing</span> <br> transportation booking made <br> more manageable
            </div>
            <div class="description-part">
                Our Cloud based transy TMS allows you to plan more effectively transport booking with organization and cut down waiting time
            </div>
            <div class="request-demo-btn">
                <button (click)="requestDemo()">Request Demo</button>
            </div>
        </div>
        <div class="right-image-section">
            <img src="./../../../assets/images/carriers/carriers_main_image.svg" alt="title-image">
        </div>
    </div>

    <div class="describe-logistics-section">
        <div class="solution-big-text">A better way to manage your logistics</div>
        <div class="solution-small-text">Transy is TMS that is quick to implement, scalable, affordable and usefull companies ranging from small sectors to larger sectors. The cloud-based TMS system makes transport management more efficient and easier to control.</div>
        <div class="ratio-part">
            <div class="single-ratio">
                <div class="percentage">80%</div>
                <div class="percent-text">Less Calls</div>
            </div>
            <div class="single-ratio">
                <div class="percentage">100%</div>
                <div class="percent-text">Efficiency</div>
            </div>
            <div class="single-ratio">
                <div class="percentage">20%</div>
                <div class="percent-text">Time Saving</div>
            </div>
        </div>
        <div class="logistic-flow">
            <div class="single-logistic" *ngFor="let item of logisticFlow;let i = index; let even=even; let odd = odd">
                <div class="check-values" [ngClass]="even ? '':'reverse-flex-direction'">
                    <div class="logistic-left-part" >
                        <img [src]="item?.img" [alt]="item.title">
                    </div>
                    <div class="logistic-right-part">
                        <div class="flow-heading">{{item?.title}}</div>
                        <div class="flow-description">{{item?.description}}</div>
                        <div class="all-steps">
                            <div class="single-step" *ngFor="let point of item.checkPoints">
                                <img src="./../../../assets/images/companies/step-point-check.svg" alt="check">
                                <div class="step-point">{{point}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="working-process">
        <div class="process-heading">How it works</div>
        <div class="process-description">
            We made transport booking process simple for everyone from accepting transport booking to assign driver
        </div>
        <div class="processs-flow" *ngIf="srcWidth > 475">
            <div class="single-process" *ngFor="let item of bookingFlow; last as last">
                <div class="flow">
                    <img src="./../../../assets/images/carriers/flow-head-pic.svg" alt="slot-booking">
                    <div class="flow-heading">{{item?.heading}}</div>
                    <div class="flow-desc">
                        {{item?.desc}}
                    </div>
                </div>
                <img src="./../../../assets/images/carriers/left-arrow.svg" alt="" *ngIf="!last" class="arrow">
            </div>
        </div>
        <div class="processs-flow" *ngIf="srcWidth < 475">
            <div class="single-process" *ngFor="let item of bookingFlow; last as last">
                <div class="flow">
                    <img src="./../../../assets/images/carriers/flow-head-pic.svg" alt="slot-booking">
                    <div class="flow-heading">{{item?.heading | titlecase}}</div>
                    <div class="flow-desc">
                        {{item?.desc | titlecase}}
                    </div>
                </div>
                <img src="./../../../assets/images/carriers/down-arrow.svg" alt="" *ngIf="!last" class="arrow">
            </div>
        </div>
    </div>

    <div class="demo-request">
        <!-- <mat-form-field class="sfull-width" appearance="outline">
            <input matInput />
        </mat-form-field> -->
        <div class="request-title">Get live Demo request now</div>
        <div class="buttonIn">
            <input type="text" id="enter" placeholder="Enter mail id">
            <button id="clear">Get Demo</button>
        </div>
    </div>
</section>
<section class="footer">
    <app-footer></app-footer>
</section>