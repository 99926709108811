<section class="top-navbar">
    <app-top-navbar></app-top-navbar>
</section>
<section class="main-section">
    <div class="title-section">
        <div class="left-title-content">
            <div class="title-heading">
               <!-- <span style="color:#FB8500; font-weight: 700;">Uplift and Streamline</span> Your <br> Logistics With Transport <br> Management -->
               Technology That Helps <br>
               <span style="color:#FB8500; font-weight: 700;">Increase Yard Efficiency</span>
            </div>
            <div class="description-part">
                Transy, is a software solution that automates processes of manually planning and tracking your daily yard operations, also facilitate scheduling, help monitor equipment and goods, and provide you with real-time information for more effective decision-making.
            </div>
            <div class="request-demo-btn">
                <button (click)="requestDemo()">Request Demo</button>
            </div>
        </div>
        <div class="right-image-section">
            <img src="./../../../assets/images/yard_management/main-image.svg" alt="title-image">
        </div>
    </div>

    <div class="describe-logistics-section">
        <div class="solution-big-text">Advance platform to manage all yard operations</div>
        <div class="solution-small-text">Gain more visibility, and control over gate, dock, and yard assets, to manage network operations more efficiently </div>
        <div class="logistic-flow">
            <div class="single-logistic" *ngFor="let item of logisticFlow;let i = index; let even=even; let odd = odd">
                <div class="check-values" [ngClass]="odd ? '':'reverse-flex-direction'">
                    <div class="logistic-left-part" >
                        <img [src]="item?.img" [alt]="item.title">
                    </div>
                    <div class="logistic-right-part">
                        <div class="flow-heading">{{item?.title}}</div>
                        <div class="flow-description">{{item?.description}}</div>
                        <div class="all-steps">
                            <div class="single-step" *ngFor="let point of item.checkPoints">
                                <img src="./../../../assets/images/companies/step-point-check.svg" alt="check">
                                <div class="step-point">{{point}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="business-impact">
        <div class="business-impact-title">The ROI of a Yard Management Solution</div>
        <div class="buisiness-impact-sub-text">ROI can come from multiple areas, including reduced detention/demurrage costs through increased visibility to trailer status and dwell times and reduced labor expense with more efficient yard jockeys;</div>
        <div class="all-status">
            <div class="single-status">
                <div class="first-circle">
                    35%
                </div>
                <div class="first-text">Increased workforce efficiency </div>
            </div>
            <div class="single-status">
                <div class="second-circle">
                    70%
                </div>
                <div class="first-text">Decreased detention costs</div>
            </div>
            <div class="single-status">
                <div class="third-circle">
                    60%
                </div>
                <div class="first-text">Management Analysis Saving</div>
            </div>
            <div class="single-status">
                <div class="fourth-circle">
                    40%
                </div>
                <div class="first-text">Decrease in labor expense</div>
            </div>
        </div>
    </div>
    <div class="management-section">
        <div class="anywhere-left">
            <div class="anywhere-big-text">Manage from anywhere</div>
            <div class="anywhere-small-text">Our AI platform is a responsive web-app whether you’re on move or anywhere. Our platform is available across all smart devices which allows to manage anywhere you like.</div>
            <div class="demo-btn">
                <button (click)="requestDemo()">Request For Demo</button>
            </div>
        </div>
        <div class="anywhere-right">
            <img src="./../../../assets/images/home_screen/mange-from-anywhere.svg" alt="manage-from-anywhere">
        </div>
    </div>
</section>
<section class="footer">
    <app-footer [background]="backgroundColor"></app-footer>
</section>