import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss','./mq-pricing.component.scss']
})
export class PricingComponent implements OnInit {

  panelOpenState = false;
  regExp = new RegExp("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$");
  email='';

  constructor(
    private snackbar: MatSnackBar,
    private titleService: Title,
    private metaService: Meta,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(
      "Transy Website Prcing Page"
    );
    this.metaService.addTag({
      name: 'description',
      content:
        'You can see different type of pricings',
    });
  }

  planTabs=[
    {name:'Monthly Billing', value :'MONTHLY',status:true},
    {name:'Annual Billing', value:'ANNUAL' ,status:false}
  ];
  planValue = 'MONTHLY';

  tabClick(item){
    this.planTabs.forEach(element => {
      if(item.value === element.value){
        element.status = true;
        this.planValue = element.value;
      }
      else{
        element.status = false;
      }
    });
  }

  monthlyCredits=[
    {
      priceTitle:"base",
      price:'20',
      packagedesc:'For most businesses that want to otpimize ',
      pros:[
        {
          check:true,
          text:'Transport Management'
        },
        {
          check:true,
          text:'Email Support'
        },
        {
          check:false,
          text:'Email and notification alert'
        },
        {
          check:false,
          text:'24*7 Support'
        },
        {
          check:false,
          text:'Unlimited Sites'
        }
      ]
    },
    {
      priceTitle:"advance",
      price:'50',
      packagedesc:'For most businesses that want to optimize',
      pros:[
        {
          check:true,
          text:'Transport Management'
        },
        {
          check:true,
          text:'Email Support'
        },
        {
          check:true,
          text:'Email and notification alert'
        },
        {
          check:true,
          text:'24*7 Support'
        },
        {
          check:false,
          text:'Unlimited Sites'
        }
      ]
    },
    {
      priceTitle:"Pro",
      price:'80',
      packagedesc:'For most businesses that want to otpimize ',
      prosHeading:'New to sales intelligence',
      pros:[
        {
          check:true,
          text:'Transport Management'
        },
        {
          check:true,
          text:'Email Support'
        },
        {
          check:true,
          text:'Email and notification alert'
        },
        {
          check:true,
          text:'24*7 Support'
        },
        {
          check:true,
          text:'Unlimited Sites'
        }
      ]
    }
  ];

  buyNow(item){
    // console.log(item);
    alert(`We are working on buy ${item?.priceTitle} Credits. Will get back soon`)
  }
  annualCredits=[
    {
      priceTitle:"base",
      price:'30',
      packagedesc:'For most businesses that want to otpimize ',
      pros:[
        {
          check:true,
          text:'Transport Management'
        },
        {
          check:true,
          text:'Email Support'
        },
        {
          check:false,
          text:'Email and notification alert'
        },
        {
          check:false,
          text:'24*7 Support'
        },
        {
          check:false,
          text:'Unlimited Sites'
        }
      ]
    },
    {
      priceTitle:"advance",
      price:'100',
      packagedesc:'For most businesses that want to optimize',
      pros:[
        {
          check:true,
          text:'Transport Management'
        },
        {
          check:true,
          text:'Email Support'
        },
        {
          check:true,
          text:'Email and notification alert'
        },
        {
          check:true,
          text:'24*7 Support'
        },
        {
          check:false,
          text:'Unlimited Sites'
        }
      ]
    },
    {
      priceTitle:"Pro",
      price:'150',
      packagedesc:'For most businesses that want to otpimize ',
      pros:[
        {
          check:true,
          text:'Transport Management'
        },
        {
          check:true,
          text:'Email Support'
        },
        {
          check:true,
          text:'Email and notification alert'
        },
        {
          check:true,
          text:'24*7 Support'
        },
        {
          check:true,
          text:'Unlimited Sites'
        }
      ]
    }
  ];

  faq=[
    {
      question:'How can I monitor my billing?',
      answer1:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      // answer2:'1 Aquire Lead Credit = 1 Profile or Contact Info'
    },
    {
      question:'Where can I find my billing history or make a change to my plan?',
      answer1:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
      question:'How do I cancel my subscription?',
      answer1:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
      question:'When does my subscription renew?',
      answer1:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
      question:'Can I get a refund? How?',
      answer1:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    }
  ];

  open(item,i){
    this.faq.forEach((element,index) => {
      if( i === index){
        this.panelOpenState = true;
      }
    });
  }
  close(item,i){
    this.faq.forEach((element,index) => {
      if(i === index){
        this.panelOpenState = false;
      }
    });
  }

  notifyMe(){}
}
