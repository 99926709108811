import { Component, HostListener, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Meta, Title } from '@angular/platform-browser';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss','./mq-contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  srcHeight ;
  srcWidth ;
  cols;
  contactFormHeight : number;
  footerFomHeight : number;
  name = '';
  email = '';
  message = '';
  phoneNumber= '';
  
  constructor(
    private _snackbar : MatSnackBar,
    private titleService: Title,
    private metaService: Meta,
    private commonService:CommonService
  ) { 
    this.getScreenSize();
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    // console.log(this.scrHeight, this.scrWidth);
  }

  ngOnInit(): void {
    if(this.srcWidth > 475){
      this.cols = 72;
    }
    else{
      this.cols = 35;
    }
    this.titleService.setTitle(
      "Transy Website contactus Page"
    );
    this.metaService.addTag({
      name: 'description',
      content:
        'You can contct from any where',
    });
  }

  contactFormSubmission(){
    if(this.name === '' && this.email === ''&&this.phoneNumber===''){
      this._snackbar.open(
        `Please Fill Mandatory Data`,
        'Close',
        {
          duration:1500
        }
      )
    }
    else{
      let details={
        email: this.email,
        message: this.message,
        name:this.name,
        mobileNo: this.phoneNumber,
      }
     this.commonService.addContactUsDetails(details).subscribe(
      {
        next:(res:any)=>{
        this.email="";
        this.name="";
        this.message="";
        this.phoneNumber="";
          this._snackbar.open(
            "Sent successfully",'Close',
            {
              duration:500
            }
          )
        },
        error:(err)=>{
          this._snackbar.open(
            "Something went wrong please try again",'Close',
            {
              duration:500
            }
          )
        }
      }
    
     )
    }
  }
}
