<nav class="navbar navbar-expand-lg navbar-light bg-light" [ngClass]="srcWidth > 475 ? '':'small-screen'">
    <a class="navbar-brand cursor-pointer" href="">
        <img src="./../../../../assets/images/navbar/branding_logo.svg" alt="branding_logo">
    </a>
    <div class="smallSreen-login-btn" *ngIf="srcWidth < 475">
      <button class="cursor-pointer" (click)="redirectToAnotherUrl()">Login</button>
    </div>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <!-- <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Solutions
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item cursor-pointer" *ngFor="let service of service" (click)="redirectUrl(service)">
                <div>
                    <img src="./../../../assets/images/top-navbar/exit.svg" alt=""><span class="dropdown-text">{{service?.dropdownText | titlecase}}</span>
                </div>
                <div class="dropdown-sub-text">Help Achieve The Best Value</div>
            </a>
          </div>
        </li> -->
        <li *ngFor="let navItem of navItems1" class="cursor-pointer" [ngClass]="navItem?.status ? 'nav-item-active' : 'nav-item'" (click)="navItemRedirect1(navItem)">
            <a class="nav-link">{{navItem?.name | titlecase}}</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Services
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item cursor-pointer" *ngFor="let service of dropDownProviding;let i = index" (click)="redirectUrl(service,i)">
                <div>
                    <span class="dropdown-text">{{service?.name | titlecase}}</span>
                </div>
            </a>
          </div>
        </li>
        <li *ngFor="let navItem of navItems2" class="cursor-pointer" [ngClass]="navItem?.status ? 'nav-item-active' : 'nav-item'" (click)="navItemRedirect2(navItem)">
          <a class="nav-link">{{navItem?.name | titlecase}}</a>
      </li>
      </ul>
      <div class="login-btn" *ngIf="srcWidth > 475">
        <button class="cursor-pointer" (click)="redirectToAnotherUrl()">Login</button>
      </div>
      <div class="demo-btn">
        <button class="cursor-pointer" (click)="requestDemo()">Request Demo</button>
      </div>
    </div>
  </nav>
